import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

// Methods
import { getListingData } from 'methods/helperMethods'

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/paper-kit.css";
// import "./assets/demo/demo.css";
import './index.css';

// Views
import Root from "views/Root.js"
import Listing from "views/Listing.js"
import Home from "views/Home.js"
import InterestForm from 'views/InterestForm';
import AboutUs from 'views/AboutUs';

import reportWebVitals from './reportWebVitals';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <div>Oops! An unexpected error occurred!</div>,
    children: [
      {
        errorElement: <div>Oops! An unexpected error occurred!</div>,
        children: [
          {index: true, element: <Home />},
          {
            path: "listing/:listingId",
            loader: getListingData,
            element: <Listing />,
          },
          {
            path: "interestform",
            element: <InterestForm />
          },
          {
            path: "about-us",
            element: <AboutUs />
          }
        ]
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
