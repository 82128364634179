import React from "react";

import IndexHeader from "components/IndexHeader";
import SectionAboutUs from "components/SectionAboutUs";

function AboutUs() {

    return (
        <>
            <IndexHeader />
            <SectionAboutUs />
        </>
    )
}

export default AboutUs;