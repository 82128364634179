import React from "react";
import { useState } from "react";
import { init, Form } from '@feathery/react';
import { addToInterestList } from "methods/helperMethods"; 

import { 
    Container
} from "reactstrap";

import check from "assets/img/check.png";

import SectionHowToApply from "components/SectionHowToApply";

function InterestForm() {
    const [formComplete, setFormComplete] = useState(false);

    init(process.env.REACT_APP_FEATHERY_SDK_KEY)

    const submitForm = (context) => {
        setFormComplete(true)
        if (context.submitFields.join_interest_list.value === "Yes") {
            const {first_name, last_name, email, phone_number} = context.submitFields
            addToInterestList(first_name.value, last_name.value, email.value, phone_number.value)
        }
    }

    return (
        <div className="nonhome-page">
            <Container style={{"maxWidth": "800px"}}>
                <h2>Interest Form</h2>
                {formComplete ? null : 
                <div className="form-instructions">
                    Interested in renting one of our units? Fill out this quick interest form and we will contact you to schedule a call.
                    <br/><br/>
                    Please keep in mind that we have the following minimum qualifications:<br/>
                    <ul>
                        <li>Credit score greater than 650</li>
                        <li>Earn 3x monthly rent</li>
                        <li>No pets</li>
                        <li>No smoking</li>
                        <li>No criminal history</li>
                        <li>No evictions</li>
                        <li>Willing to live with roommates</li>
                        <li>Only 1 person per room</li>
                    </ul> 
                    Note: Preference is given to year long leases, and leases less than one year will be priced higher. 
                    We screen for evictions, criminal background, and credit history as part of the application. The 
                    application costs $50 and ensures that all roommates feel safe when living with one another.
                </div>}

                {formComplete ? 
                    <div className="form-complete">
                        <img className="check-image" alt="checkmark" src={check}/>
                        <div className="form-complete-description">
                            Thank you for filling out our form! <br/><br/>
                            Your response has been recorded and we will reach out to you soon with next steps
                        </div>
                    </div> :
                    <div className="interest-form-container">
                        <Form 
                            formName='InterestForm' 
                            initialLoader={{show: true}}
                            onSubmit={(context) => submitForm(context)}
                        />
                    </div>
                }
                <SectionHowToApply id="how-to-apply" sectionTitle="What's next?" stepNumber={formComplete ? 2 : 1}/>
            </Container>
        </div>
    )
}

export default InterestForm;